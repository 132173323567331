import React from 'react';
import { useAppSelector } from '../../app/hooks';
import { Article, Network, selectNetwork } from '@cyberpnk/component-library';
import commonAddresses from "@cyberpnk/solidity-library/contract-addresses.json";
import samplePfpBg from "./punk_terraform.svg";
import ensSample from "./ens_punk_terraform.png";

export function AboutPage() {
  const network = useAppSelector(selectNetwork) as Network;

  return (
    <>
      <Article>
        <h1>What is this?</h1>
        <p>Do you set nice generative art NFTs as your PFP background, like this?</p>
        <img alt="Punk + Terraform" src={samplePfpBg} />
        <p>Do you wish there was a way to do it on-chain, so that you could use it as your avatar in ENS domains, or even Twitter, while keeping the ability to prove you own <i>both</i> the PFP NFT <i>and</i> the background NFT?</p>
        <p>Then today is your lucky day, because I've done exactly that!</p>
      </Article>
      <Article>
        <h1>How does it work?</h1>
        <p>First, confirm that the NFTs you want to use as PFP and background are both on-chain and supported.  We support these PFPs</p>
        <ul>
          <li>CryptoPunks</li>
          <li>Anonymice</li>
          <li>Anonymice Babies</li>
          <li>EthTerrestrials</li>
          <li>Luchadores</li>
        </ul>
        <p>And these backgrounds:</p>
        <ul>
          <li>Terraforms</li>
          <li>Autoglyphs</li>
          <li>Headscapes</li>
          <li>Kinochromes</li>
          <li>PonziRugs</li>
        </ul>
        <p>If the on-chain NFT you want to use isn't supported yet, drop me a message in <a target="_blank" rel="noreferrer" href="https://twitter.com/Cyberpnk9462">Twitter</a> or <a target="_blank" rel="noreferrer" href="https://discord.gg/eeUUCjVF4T">Discord</a></p>
        <p>Then, head over to <a href="#/bg">change your background</a> and set it to the NFT you want.  This is the only part that costs gas. The background is set per wallet, not per PFP, so once set all your supported PFPs will use the same background.</p>
        <p>And last but not least, you need to use our "View" contracts instead of the original PFP contracts.</p>
        <p>For example, if you want to set your CryptoPunk + Background as your ENS avatar, instead of the CryptoPunks contract address ({commonAddresses?.[network]?.CryptoPunksMarket}) you'll need to use our View contract for punks (0x05933243edD2388038A2C0B01B6732aFCF6c5093) that adds your background to it. Like this:</p>
        <img alt="ENS example" src={ensSample} />
      </Article>
      <Article>
        <h1>What's the point, really?</h1>
        <p>Sure, you can always mint a silly old JPEG on OS and point your avatar to it, but with our system you can prove <i>you own them</i></p>
        <p>Plus it's really cool, it's all on-chain!</p>
      </Article>
    </>
  );
}
