import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  connectWalletAsync, newWalletConnected,
  SimpleAsyncState, walletDisconnected,
  buildSimpleAsyncCases
} from '@cyberpnk/component-library';
import {
  addressToBackground,
  Background,
  bgNftAdapterGetEmbeddableSvg,
  bgNftAdapterIsNotYours,
  BgNftAdapterRequest,
  pfpNftAdapterGetEmbeddableSvg,
  pfpNftAdapterIsNotYours,
  PfpNftAdapterRequest,
  pfpToCollectionPfpBg, setNft
} from './pfpBgApi';

export interface BackgroundPageState {
  selectedPfpContract: string,
  pfpTokenId: string,
  selectedBgContract: string,
  bgTokenId: string,

  setNft: SimpleAsyncState<string, BgNftAdapterRequest>;
  bgNftAdapterIsNotYours: SimpleAsyncState<boolean, BgNftAdapterRequest>;
  pfpNftAdapterIsNotYours: SimpleAsyncState<boolean, PfpNftAdapterRequest>;
  pfpNftAdapterGetEmbeddableSvg: SimpleAsyncState<string, PfpNftAdapterRequest>;
  bgNftAdapterGetEmbeddableSvg: SimpleAsyncState<string, BgNftAdapterRequest>;

  addressToBackground: SimpleAsyncState<Background>;
  pfpToCollectionPfpBg: SimpleAsyncState<string>;
}

const initialState: BackgroundPageState = {
  selectedPfpContract: "",
  pfpTokenId: "",
  selectedBgContract: "",
  bgTokenId: "",

  setNft: { loading: false, },
  bgNftAdapterIsNotYours: { loading: false, },
  pfpNftAdapterIsNotYours: { loading: false, },
  pfpNftAdapterGetEmbeddableSvg: { loading: false, },
  bgNftAdapterGetEmbeddableSvg: { loading: false, },
  addressToBackground: { loading: false, },
  pfpToCollectionPfpBg: { loading: false, },
};

export const initBackgroundPage = createAction<void>("backgroundPage/initBackgroundPage");

export const setNftAsync = createAsyncThunk(
  'backgroundPage/setNft',
  async ({ bgNftContract, tokenId }: BgNftAdapterRequest, thunkAPI) => {
    return await setNft({ bgNftContract, tokenId });
  }
);

export const addressToBackgroundAsync = createAsyncThunk(
  'backgroundPage/addressToBackground',
  async () => {
    return await addressToBackground();
  }
);

export const pfpToCollectionPfpBgAsync = createAsyncThunk(
  'backgroundPage/pfpToCollectionPfpBg',
  async ({ pfpContractAddress }: { pfpContractAddress: string }, thunkAPI) => {
    return await pfpToCollectionPfpBg({ pfpContractAddress });
  }
);

export const bgNftAdapterGetEmbeddableSvgAsync = createAsyncThunk(
  'backgroundPage/bgNftAdapterGetEmbeddableSvg',
  async ({ bgNftContract, tokenId }: BgNftAdapterRequest, thunkAPI) => {
    return await bgNftAdapterGetEmbeddableSvg({ bgNftContract, tokenId });
  }
);

export const bgNftAdapterIsNotYoursAsync = createAsyncThunk(
  'backgroundPage/bgNftAdapterIsNotYours',
  async ({ bgNftContract, tokenId }: BgNftAdapterRequest, thunkAPI) => {
    return await bgNftAdapterIsNotYours({ bgNftContract, tokenId });
  }
);

export const pfpNftAdapterGetEmbeddableSvgAsync = createAsyncThunk(
  'backgroundPage/pfpNftAdapterGetEmbeddableSvg',
  async ({ pfpContractAddress, tokenId }: PfpNftAdapterRequest, thunkAPI) => {
    return await pfpNftAdapterGetEmbeddableSvg({ pfpContractAddress, tokenId });
  }
);

export const pfpNftAdapterIsNotYoursAsync = createAsyncThunk(
  'backgroundPage/pfpNftAdapterIsNotYours',
  async (request: PfpNftAdapterRequest, thunkAPI) => {
    return await pfpNftAdapterIsNotYours(request);
  }
);

export const backgroundPageSlice = createSlice({
  name: 'backgroundPage',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    changeSelectedPfpContract: (state: any, action: { payload: { selectedPfpContract?: string } }) => {
      state.selectedPfpContract = action.payload.selectedPfpContract;
    },
    changeSelectedBgContract: (state: any, action: { payload: { selectedBgContract?: string } }) => {
      state.selectedBgContract = action.payload.selectedBgContract;
    },
    changePfpTokenId: (state: any, action: { payload: { tokenId?: string } }) => {
      state.pfpTokenId = action.payload.tokenId;
    },
    changeBgTokenId: (state: any, action: { payload: { tokenId?: string } }) => {
      state.bgTokenId = action.payload.tokenId;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    buildSimpleAsyncCases(builder, setNftAsync, "setNft");
    buildSimpleAsyncCases(builder, bgNftAdapterIsNotYoursAsync, "bgNftAdapterIsNotYours");
    buildSimpleAsyncCases(builder, pfpNftAdapterIsNotYoursAsync, "pfpNftAdapterIsNotYours");
    buildSimpleAsyncCases(builder, pfpNftAdapterGetEmbeddableSvgAsync, "pfpNftAdapterGetEmbeddableSvg");
    buildSimpleAsyncCases(builder, bgNftAdapterGetEmbeddableSvgAsync, "bgNftAdapterGetEmbeddableSvg");
    buildSimpleAsyncCases(builder, pfpToCollectionPfpBgAsync, "pfpToCollectionPfpBg");
    buildSimpleAsyncCases(builder, addressToBackgroundAsync, "addressToBackground");

    builder
      .addCase(connectWalletAsync.pending, (state) => {
        state.setNft = initialState.setNft;
        state.bgNftAdapterIsNotYours = initialState.bgNftAdapterIsNotYours;
        state.pfpNftAdapterIsNotYours = initialState.pfpNftAdapterIsNotYours;
        state.pfpNftAdapterGetEmbeddableSvg = initialState.pfpNftAdapterGetEmbeddableSvg;
        state.bgNftAdapterGetEmbeddableSvg = initialState.bgNftAdapterGetEmbeddableSvg;
        state.pfpToCollectionPfpBg = initialState.pfpToCollectionPfpBg;
        state.addressToBackground = initialState.addressToBackground;
      })
      .addCase(newWalletConnected, (state) => {
        state.setNft = initialState.setNft;
        state.bgNftAdapterIsNotYours = initialState.bgNftAdapterIsNotYours;
        state.pfpNftAdapterIsNotYours = initialState.pfpNftAdapterIsNotYours;
        state.pfpNftAdapterGetEmbeddableSvg = initialState.pfpNftAdapterGetEmbeddableSvg;
        state.bgNftAdapterGetEmbeddableSvg = initialState.bgNftAdapterGetEmbeddableSvg;
        state.pfpToCollectionPfpBg = initialState.pfpToCollectionPfpBg;
        state.addressToBackground = initialState.addressToBackground;
      })
      .addCase(walletDisconnected, (state) => {
        state.setNft = initialState.setNft;
        state.bgNftAdapterIsNotYours = initialState.bgNftAdapterIsNotYours;
        state.pfpNftAdapterIsNotYours = initialState.pfpNftAdapterIsNotYours;
        state.pfpNftAdapterGetEmbeddableSvg = initialState.pfpNftAdapterGetEmbeddableSvg;
        state.bgNftAdapterGetEmbeddableSvg = initialState.bgNftAdapterGetEmbeddableSvg;
        state.pfpToCollectionPfpBg = initialState.pfpToCollectionPfpBg;
        state.addressToBackground = initialState.addressToBackground;
      })
  },
});

export const {
  changeSelectedPfpContract,
  changeSelectedBgContract,
  changePfpTokenId,
  changeBgTokenId,
} = backgroundPageSlice.actions;

export const selectackgroundPageState = (state: any): BackgroundPageState => state.backgroundPage;

export const selectSelectedPfpContract = (state: any) => {
  return selectackgroundPageState(state).selectedPfpContract
};
export const selectPfpTokenId = (state: any) => selectackgroundPageState(state).pfpTokenId;
export const selectSelectedBgContract = (state: any) => selectackgroundPageState(state).selectedBgContract;
export const selectBgTokenId = (state: any) => selectackgroundPageState(state).bgTokenId;

export const selectSetNft = (state: any) => selectackgroundPageState(state).setNft;
export const selectBgNftAdapterIsNotYours = (state: any) => selectackgroundPageState(state).bgNftAdapterIsNotYours;
export const selectPfpNftAdapterIsNotYours = (state: any) => selectackgroundPageState(state).pfpNftAdapterIsNotYours;
export const selectBgNftAdapterGetEmbeddableSvg = (state: any) => selectackgroundPageState(state).bgNftAdapterGetEmbeddableSvg;
export const selectPfpNftAdapterGetEmbeddableSvg = (state: any) => selectackgroundPageState(state).pfpNftAdapterGetEmbeddableSvg;
export const selectAddressToBackground = (state: any) => selectackgroundPageState(state).addressToBackground;
export const selectPfpToCollectionPfpBg = (state: any) => selectackgroundPageState(state).pfpToCollectionPfpBg;

export const backgroundPageReducer = backgroundPageSlice.reducer;

export default backgroundPageSlice.reducer;
