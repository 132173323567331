import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  changeBgTokenId,
  changePfpTokenId,
  changeSelectedBgContract,
  changeSelectedPfpContract,
  selectAddressToBackground,
  selectBgNftAdapterGetEmbeddableSvg,
  selectBgNftAdapterIsNotYours,
  selectBgTokenId,
  selectPfpNftAdapterGetEmbeddableSvg,
  selectPfpNftAdapterIsNotYours,
  selectPfpToCollectionPfpBg,
  selectPfpTokenId,
  selectSelectedBgContract,
  selectSelectedPfpContract,
  selectSetNft,
  setNftAsync,
} from './backgroundPageSlice';
import styles from './BackgroundPage.module.css';
import {
  Article, Button, ConnectWallet,
  ErrorMessage, Input,
  isUnknownNetwork,
  LoadingOrErrorOrContent,
  Network,
  Select, selectConnected, selectNetwork
} from '@cyberpnk/component-library';
import { getBgs, getPfps } from '../../app/common';

export function BackgroundPage() {
  const dispatch = useAppDispatch();
  const connected = useAppSelector(selectConnected);
  const network: Network = useAppSelector(selectNetwork);

  const isKnownNetwork = !isUnknownNetwork(network);

  const properlyConnected = connected && isKnownNetwork;
  const showConnectStep = !properlyConnected;
  const showMain = properlyConnected;

  const [pfpSelected, setPfpSelected] = useState<string | undefined>(useAppSelector(selectSelectedPfpContract));
  const [pfpTokenIdValue, setPfpTokenIdValue] = useState<string | undefined>(useAppSelector(selectPfpTokenId));

  const [bgSelected, setBgSelected] = useState<string | undefined>(useAppSelector(selectSelectedBgContract));
  const [bgTokenIdValue, setBgTokenIdValue] = useState<string | undefined>(useAppSelector(selectBgTokenId));

  const pfpOptions = getPfps(network).map(({ name, pfpContractAddress }) => {
    return ({ label: name, value: pfpContractAddress })
  });
  const bgOptions = getBgs(network).map(({ name, bgContractAddress }) => {
    return ({ label: name, value: bgContractAddress })
  });

  const isPfpNotYours = useAppSelector(selectPfpNftAdapterIsNotYours);
  const isBgNotYours = useAppSelector(selectBgNftAdapterIsNotYours);

  const pfpSvg = useAppSelector(selectPfpNftAdapterGetEmbeddableSvg);
  const bgSvg = useAppSelector(selectBgNftAdapterGetEmbeddableSvg);

  const pfpToCollectionPfpBg = useAppSelector(selectPfpToCollectionPfpBg);

  const setNft = useAppSelector(selectSetNft);

  const addressToBackground = useAppSelector(selectAddressToBackground);

  const imageLoading = pfpSvg?.loading || bgSvg?.loading;
  const imageError = pfpSvg?.error || bgSvg?.error;

  const setNftLoading = setNft?.loading;
  const setNftError = setNft?.error;

  const bgYoursLoading = isBgNotYours?.loading;
  const pfpYoursLoading = isPfpNotYours?.loading;

  const bgYoursError = isBgNotYours.error;
  const pfpYoursError = isPfpNotYours?.error;

  const collectionLoading = pfpToCollectionPfpBg.loading;
  const collectionError = pfpToCollectionPfpBg.error;

  const dispatchChangePfp = () => {
    dispatch(changePfpTokenId({ tokenId: pfpTokenIdValue }))
  };
  const dispatchChangeBg = () => {
    dispatch(changeBgTokenId({ tokenId: bgTokenIdValue }))
  };

  const isSameBg = !setNftLoading && !setNftError && addressToBackground?.response?.nftBgContract === bgSelected && addressToBackground?.response?.nftBgTokenId === bgTokenIdValue;

  const bgChangedNotLoaded = bgSelected !== isBgNotYours?.request?.bgNftContract ||
    bgTokenIdValue !== isBgNotYours?.request?.tokenId;

  const cannotChangeBg = imageLoading ||
    setNftLoading ||
    bgYoursLoading ||
    bgChangedNotLoaded ||
    imageError ||
    isBgNotYours?.response ||
    bgTokenIdValue === undefined ||
    bgTokenIdValue === "" ||
    isNaN(+bgTokenIdValue) ||
    bgSelected === undefined ||
    bgSelected === "" ||
    isSameBg;

  const bgInputsDisabled = isBgNotYours.loading;
  const pfpInputsDisabled = isPfpNotYours.loading;

  const svg = `${bgSvg?.response ? bgSvg?.response : ""}${pfpSvg?.response ? pfpSvg?.response : ""}`;

  return (
    <>
      {showConnectStep ?
        <ConnectWallet useAppDispatch={useAppDispatch} useAppSelector={useAppSelector} />
        : null}

      {showMain ? <>
        <div className={styles.layout}>
          <div className={styles.selects}>
            <Article>
              <h1>Select a PFP you own</h1>
              <p>
                <Select options={pfpOptions}
                  selectedValue={pfpSelected}
                  disabled={pfpInputsDisabled}
                  placeholder={"Select PFP Collection"}
                  onChange={(value?: string) => {
                    setPfpSelected(value);
                    dispatch(changeSelectedPfpContract({ selectedPfpContract: value }));
                  }} ></Select>{' '}
                <Input value={pfpTokenIdValue}
                  placeholder={"Token ID"}
                  disabled={pfpInputsDisabled}
                  error={isPfpNotYours.response}
                  onChange={setPfpTokenIdValue}
                  onEnter={dispatchChangePfp} />
              </p>
              <div className={styles.buttonAndMessage}>
                <p>
                  <Button disabled={pfpInputsDisabled}
                    onClick={dispatchChangePfp}>Load PFP</Button>
                </p>
                <LoadingOrErrorOrContent loading={pfpYoursLoading} error={pfpYoursError && "Tx Error"} loadingText={"Checking ownership..."}>
                  {isPfpNotYours.response ? <ErrorMessage>Not your token</ErrorMessage> : null}
                </LoadingOrErrorOrContent>
              </div>
            </Article>

            <Article>
              <h1>Select a Background you own</h1>
              <p><Select options={bgOptions}
                disabled={bgInputsDisabled}
                selectedValue={bgSelected}
                placeholder={"Select Collection"}
                onChange={(value?: string) => {
                  setBgSelected(value);
                  dispatch(changeSelectedBgContract({ selectedBgContract: value }));
                }} ></Select>{' '}
                <Input value={bgTokenIdValue}
                  disabled={bgInputsDisabled}
                  placeholder={"Token ID"}
                  error={isBgNotYours.response}
                  onChange={setBgTokenIdValue}
                  onEnter={dispatchChangeBg} />
              </p>
              <div className={styles.buttonAndMessage}>
                <p>
                  <Button disabled={bgInputsDisabled}
                    onClick={dispatchChangeBg}>Load Background</Button>
                </p>
                <LoadingOrErrorOrContent loading={bgYoursLoading} error={bgYoursError && "Tx Error"} loadingText={"Checking ownership..."}>
                  {isBgNotYours.response ? <ErrorMessage>Not your token</ErrorMessage> : null}
                </LoadingOrErrorOrContent>
              </div>
            </Article>

            <Article>
              <h1>Save it on-chain</h1>
              <div className={styles.buttonAndMessage}>
                <p>
                  <Button cta={true} disabled={!!cannotChangeBg} onClick={() => {
                    if (bgSelected && bgTokenIdValue) {
                      dispatch(setNftAsync({ bgNftContract: bgSelected, tokenId: bgTokenIdValue }));
                    }
                  }}>Set your Background</Button>
                </p>
                <LoadingOrErrorOrContent loading={setNftLoading} error={setNftError && "Tx Error"} loadingText={"Pending tx..."}>
                  {isSameBg ? <p>This is your currently stored background</p> : null}
                </LoadingOrErrorOrContent>
              </div>
            </Article>

          </div>
          <div className={styles.pic}>

            <Article>
              <LoadingOrErrorOrContent loading={imageLoading} error={imageError && "Select Pfp and Background NFTs"} loadingText={"Loading Image..."}>
                <p>
                  <svg xmlns="http://www.w3.org/2000/svg" version="1.2" viewBox="0 0 640 640" dangerouslySetInnerHTML={{ __html: svg }}></svg>
                </p>
              </LoadingOrErrorOrContent>
              <LoadingOrErrorOrContent loading={collectionLoading} error={collectionError && "Tx Error"} loadingText={""}>
                {pfpToCollectionPfpBg?.response ?
                  <p>To use this as your PFP, copy and use this contract: <Input addressWidth={true}
                    value={pfpToCollectionPfpBg?.response}
                    readOnly={true} /> with the same token ID in applications like <a
                      href="http://app.ens.domains">ENS</a>, instead of the original one for your PFP.
                  </p>
                  : null}
              </LoadingOrErrorOrContent>
            </Article>
          </div>
        </div>
      </>
        : null}
    </>

  );
}
