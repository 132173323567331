import { ofType, StateObservable } from "redux-observable";
import { filter, map, switchMap, tap } from "rxjs/operators";
//@ts-ignore
import { merge } from "rxjs";
import {
  addressToBackgroundAsync,
  bgNftAdapterGetEmbeddableSvgAsync,
  bgNftAdapterIsNotYoursAsync,
  changeBgTokenId, changePfpTokenId,
  changeSelectedBgContract, changeSelectedPfpContract,
  initBackgroundPage, pfpNftAdapterGetEmbeddableSvgAsync,
  pfpNftAdapterIsNotYoursAsync, pfpToCollectionPfpBgAsync, selectBgTokenId,
  selectPfpTokenId, selectSelectedBgContract,
  selectSelectedPfpContract, setNftAsync
} from "./backgroundPageSlice";
import { RootState } from "../../app/store";
import { attemptAutoConnectAsync, connectWalletAsync, selectConnected } from "@cyberpnk/component-library";
import { Action } from "@reduxjs/toolkit";

export const LOCALSTORAGE_KEY_STORE_BACKGROUNDPAGE_BGTOKENID = "store.backgroundPage.bgTokenId";
export const LOCALSTORAGE_KEY_STORE_BACKGROUNDPAGE_PFPTOKENID = "store.backgroundPage.pfpTokenId";
export const LOCALSTORAGE_KEY_STORE_BACKGROUNDPAGE_SELECTEDPFPCONTRACT = "store.backgroundPage.selectedPfpContract";
export const LOCALSTORAGE_KEY_STORE_BACKGROUNDPAGE_SELECTEDBGCONTRACT = "store.backgroundPage.selectedBgContract";

export const initBgTokenIdEpic = (action$: any, state$: StateObservable<RootState>) => action$.pipe(
  ofType(initBackgroundPage),
  map(() => {
    return window.localStorage.getItem(LOCALSTORAGE_KEY_STORE_BACKGROUNDPAGE_BGTOKENID)
  }),
  filter(tokenId => {
    return !!tokenId
  }),
  map((tokenId: string) => changeBgTokenId({ tokenId })),
);

export const initPfpTokenIdEpic = (action$: any, state$: StateObservable<RootState>) => action$.pipe(
  ofType(initBackgroundPage),
  map(() => window.localStorage.getItem(LOCALSTORAGE_KEY_STORE_BACKGROUNDPAGE_PFPTOKENID)),
  filter(tokenId => !!tokenId),
  map((tokenId: string) => changePfpTokenId({ tokenId })),
);

export const initSelectedPfpEpic = (action$: any, state$: StateObservable<RootState>) => action$.pipe(
  ofType(initBackgroundPage),
  map(() => {
    return window.localStorage.getItem(LOCALSTORAGE_KEY_STORE_BACKGROUNDPAGE_SELECTEDPFPCONTRACT)
  }),
  filter(selectedPfpContract => {
    return !!selectedPfpContract
  }),
  map((selectedPfpContract: string) => changeSelectedPfpContract({ selectedPfpContract })),
);

export const initSelectedBgEpic = (action$: any, state$: StateObservable<RootState>) => action$.pipe(
  ofType(initBackgroundPage),
  map(() => window.localStorage.getItem(LOCALSTORAGE_KEY_STORE_BACKGROUNDPAGE_SELECTEDBGCONTRACT)),
  filter(selectedBgContract => !!selectedBgContract),
  map((selectedBgContract: string) => changeSelectedBgContract({ selectedBgContract })),
);

export const storeSelectedBgEpic = (action$: any, state$: StateObservable<RootState>) => action$.pipe(
  ofType(changeSelectedBgContract),
  map(() => selectSelectedBgContract(state$.value)),
  tap((bgNftContract: string) => {
    window.localStorage.setItem(LOCALSTORAGE_KEY_STORE_BACKGROUNDPAGE_SELECTEDBGCONTRACT, bgNftContract || "");
  }),
  filter(() => {
    return false;
  }),
);

export const storeBgTokenIdEpic = (action$: any, state$: StateObservable<RootState>) => action$.pipe(
  ofType(changeBgTokenId),
  map(() => selectBgTokenId(state$.value)),
  tap((tokenId: string) => {
    window.localStorage.setItem(LOCALSTORAGE_KEY_STORE_BACKGROUNDPAGE_BGTOKENID, tokenId || "");
  }),
  filter(() => {
    return false;
  }),
);

export const changeBgEpic = (action$: any, state$: StateObservable<RootState>) => merge(
  action$.pipe(ofType(changeSelectedBgContract)),
  action$.pipe(ofType(changeBgTokenId)),
  action$.pipe(ofType(connectWalletAsync.fulfilled)),
  action$.pipe(ofType(attemptAutoConnectAsync.fulfilled)),
).pipe(
  map(() => {
    return ({
      tokenId: selectBgTokenId(state$.value),
      bgNftContract: selectSelectedBgContract(state$.value),
      connected: selectConnected(state$.value),
    })
  }),
  filter(({ tokenId, bgNftContract, connected }) => {
    return connected && !!tokenId && !!bgNftContract && !isNaN(+(tokenId));
  }),
  switchMap(({ tokenId, bgNftContract }) => [
    bgNftAdapterIsNotYoursAsync({ bgNftContract, tokenId }) as unknown as Action,
    bgNftAdapterGetEmbeddableSvgAsync({ bgNftContract, tokenId }) as unknown as Action,
  ]),
);

export const storeSelectedPfpEpic = (action$: any, state$: StateObservable<RootState>) => action$.pipe(
  ofType(changeSelectedPfpContract),
  map(() => selectSelectedPfpContract(state$.value)),
  tap((pfpNftContract: string) => {
    window.localStorage.setItem(LOCALSTORAGE_KEY_STORE_BACKGROUNDPAGE_SELECTEDPFPCONTRACT, pfpNftContract || "");
  }),
  filter(() => {
    return false;
  }),
);

export const storePfpTokenIdEpic = (action$: any, state$: StateObservable<RootState>) => action$.pipe(
  ofType(changePfpTokenId),
  map(() => selectPfpTokenId(state$.value)),
  tap((tokenId: string) => {
    window.localStorage.setItem(LOCALSTORAGE_KEY_STORE_BACKGROUNDPAGE_PFPTOKENID, tokenId || "");
  }),
  filter(() => {
    return false;
  }),
);

export const changePfpEpic = (action$: any, state$: StateObservable<RootState>) => merge(
  action$.pipe(ofType(changeSelectedPfpContract)),
  action$.pipe(ofType(changePfpTokenId)),
  action$.pipe(ofType(connectWalletAsync.fulfilled)),
  action$.pipe(ofType(attemptAutoConnectAsync.fulfilled)),
).pipe(
  map(() => {
    return ({
      tokenId: selectPfpTokenId(state$.value),
      pfpContractAddress: selectSelectedPfpContract(state$.value),
      connected: selectConnected(state$.value),
    })
  }),
  filter(({ tokenId, pfpContractAddress, connected }) => {
    return connected && !!tokenId && !!pfpContractAddress && !isNaN(+(tokenId));
  }),
  switchMap(({ tokenId, pfpContractAddress }) => [
    pfpNftAdapterIsNotYoursAsync({ pfpContractAddress, tokenId }) as unknown as Action,
    pfpNftAdapterGetEmbeddableSvgAsync({ pfpContractAddress, tokenId }) as unknown as Action,
  ]),
);

export const laodCollectionEpic = (action$: any, state$: StateObservable<RootState>) => merge(
  action$.pipe(ofType(changeSelectedPfpContract)),
  action$.pipe(ofType(connectWalletAsync.fulfilled)),
  action$.pipe(ofType(attemptAutoConnectAsync.fulfilled)),
).pipe(
  map(() => {
    return ({
      pfpContractAddress: selectSelectedPfpContract(state$.value),
      connected: selectConnected(state$.value),
    })
  }),
  filter(({ pfpContractAddress, connected }) => {
    return connected && !!pfpContractAddress;
  }),
  switchMap(({ pfpContractAddress }) => [
    pfpToCollectionPfpBgAsync({ pfpContractAddress }) as unknown as Action,
  ]),
);

export const reloadBackgroundDataEpic = (action$: any, state$: StateObservable<RootState>) => merge(
  action$.pipe(ofType(setNftAsync.fulfilled)),
  action$.pipe(ofType(connectWalletAsync.fulfilled)),
  action$.pipe(ofType(attemptAutoConnectAsync.fulfilled)),
).pipe(
  map(() => addressToBackgroundAsync() as unknown as Action),
);
