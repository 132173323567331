import React from 'react';
import styles from './App.module.css';
import { useAppSelector, useAppDispatch } from './app/hooks';
import {
  Network, Footer,
  ConnectWallet, selectConnected,
  selectNetwork,
  TopBar,
  ConnectWalletStatus
} from '@cyberpnk/component-library';
import { version } from '../package.json';
import { BackgroundPage } from './features/BackgroundPage/BackgroundPage';
import { Routes, Route } from "react-router-dom";
import { AboutPage } from './features/AboutPage/AboutPage';

function App() {
  const network: Network = useAppSelector(selectNetwork);

  return (
    <>
      <div className={styles.App}>
        <TopBar>
          <a href="#/about">What is this?</a>&nbsp;&nbsp;&nbsp;<a href="#/bg">Change Your Background</a>&nbsp;&nbsp;&nbsp;
          <ConnectWalletStatus useAppDispatch={useAppDispatch} useAppSelector={useAppSelector} />
        </TopBar>
        <main>
          <h2>Use an NFT as background for your pfp NFT</h2>

          <div className={styles.main}>
            <Routes>
              <Route path="/" element={<BackgroundPage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/bg" element={<BackgroundPage />} />
            </Routes>

          </div>
        </main>
        <div className={styles.footer}>
          <Footer version={version} network={network} />
        </div>
      </div>
    </>
  );
}

export default App;
