import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import {
  connectWalletReducer,
  initWallet,
  initWalletEpic,
} from '@cyberpnk/component-library';
import { backgroundPageReducer, initBackgroundPage } from '../features/BackgroundPage/backgroundPageSlice';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { routerNavigated } from './history';
import { createBrowserHistory } from 'history';
import {
  changeBgEpic, changePfpEpic,
  initBgTokenIdEpic, initPfpTokenIdEpic,
  initSelectedBgEpic, initSelectedPfpEpic,
  laodCollectionEpic,
  reloadBackgroundDataEpic,
  storeBgTokenIdEpic,
  storePfpTokenIdEpic,
  storeSelectedBgEpic,
  storeSelectedPfpEpic
} from '../features/BackgroundPage/backgroundPageEpic';


const epicMiddleware = createEpicMiddleware();

export const store = configureStore({
  reducer: {
    connectWallet: connectWalletReducer,
    backgroundPage: backgroundPageReducer,
  },
  middleware: (getDefaultMiddleware) => {
    const middleware = getDefaultMiddleware();
    return middleware.concat(epicMiddleware);
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const rootEpic = combineEpics(
  initWalletEpic,
  initBgTokenIdEpic,
  initPfpTokenIdEpic,
  initSelectedPfpEpic,
  initSelectedBgEpic,
  changeBgEpic,
  changePfpEpic,
  reloadBackgroundDataEpic,
  storeSelectedPfpEpic,
  storeSelectedBgEpic,
  storeBgTokenIdEpic,
  storePfpTokenIdEpic,
  laodCollectionEpic,
);

// @ts-ignore
epicMiddleware.run(rootEpic);

store.dispatch(initWallet());
store.dispatch(initBackgroundPage());

const myHistory = createBrowserHistory()

// // Get the current location.
const location = myHistory.location;


// Listen for changes to the current location.
// returns "unlisten"
myHistory.listen(({ location }) => {
  store.dispatch(routerNavigated({ path: location.pathname }));
});

store.dispatch(routerNavigated({ path: location.pathname }));

export const history = myHistory;