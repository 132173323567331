import { callAndWaitForEvent, getConnectedAddress, readFromCacheOrCallMethod } from "@cyberpnk/component-library";
import {
  getCollectionPfpBgContract,
  getCollectionPfpBgManagerContract,
  getErc721Contract,
  getNftAdapterContract,
  getPfpBgContract,
} from "../../app/common";

declare global {
  interface Window { ethereum: any; }
}

export interface BgNftAdapterRequest {
  bgNftContract: string,
  tokenId: string
}

export interface PfpNftAdapterRequest {
  pfpContractAddress: string,
  tokenId: string
}

export const bgNftContractToBgAdapterContract = async ({ bgNftContract }: { bgNftContract: string }) => {
  const pfpBg = await getPfpBgContract();
  // return await pfpBg.methods.bgNftContractToBgAdapterContract(bgNftContract).call();
  return await readFromCacheOrCallMethod(pfpBg, "bgNftContractToBgAdapterContract", [bgNftContract]);
}

export const bgAdapterContract = async ({ bgNftContract }: { bgNftContract: string }) => {
  const address = await bgNftContractToBgAdapterContract({ bgNftContract });
  return await getNftAdapterContract(address);
}

export const pfpToCollectionPfpBg = async ({ pfpContractAddress }: { pfpContractAddress: string }) => {
  const collectionPfpBgManager = await getCollectionPfpBgManagerContract();
  // return await collectionPfpBgManager.methods.pfpToCollectionPfpBg(pfpContractAddress).call();
  return await readFromCacheOrCallMethod(collectionPfpBgManager, "pfpToCollectionPfpBg", [pfpContractAddress]);

}

export const collectionPfpBgContract = async ({ pfpContractAddress }: { pfpContractAddress: string }) => {
  const address = await pfpToCollectionPfpBg({ pfpContractAddress });
  return await getCollectionPfpBgContract(address);
}

export const pfpAdapterContract = async ({ pfpContractAddress }: { pfpContractAddress: string }) => {
  const collectionPfpBg = await collectionPfpBgContract({ pfpContractAddress });
  // const address = await collectionPfpBg.methods.pfpAdapterContract().call();
  const address = await readFromCacheOrCallMethod(collectionPfpBg, "pfpAdapterContract", []);

  return await getNftAdapterContract(address);
}


export const getTokenURI = async ({ contractAddress, tokenId }: { contractAddress: string, tokenId: string }) => {
  const erc721 = await getErc721Contract(contractAddress);
  return await erc721.methods.tokenURI(tokenId).call();
}

export const getCollectionPfpBgTokenURI = async ({ pfpContractAddress, tokenId }: PfpNftAdapterRequest) => {
  const collectionPfpBg = await collectionPfpBgContract({ pfpContractAddress });
  return await collectionPfpBg.methods.tokenURI(tokenId).call();
}

export const setNft = async ({ bgNftContract, tokenId }: BgNftAdapterRequest) => {
  const user = await getConnectedAddress();

  return callAndWaitForEvent(getPfpBgContract, "setNft", [bgNftContract, tokenId], "ChangeBackground", { sender: user });
}

export interface Background {
  color: string;
  nftBgContract: string;
  nftBgTokenId: string;
}

export const addressToBackground = async (): Promise<Background> => {
  const pfpBg = await getPfpBgContract();
  const user = await getConnectedAddress();
  const result = await pfpBg.methods.addressToBackground(user).call();
  return {
    color: result.color,
    nftBgContract: result.nftBgContract,
    nftBgTokenId: result.nftBgTokenId
  };
}

export const getBgSvg = async () => {
  const user = await getConnectedAddress();
  const pfpBg = await getPfpBgContract();
  return await pfpBg.methods.getBgSvg(user).call();
}

export const bgNftAdapterGetSvg = async ({ bgNftContract, tokenId }: BgNftAdapterRequest) => {
  const bgAdapter = await bgAdapterContract({ bgNftContract });
  // return await bgAdapter.methods.getSvg(tokenId).call();
  return readFromCacheOrCallMethod(bgAdapter, "getSvg", [tokenId]);
}

export const bgNftAdapterGetDataUriSvg = async ({ bgNftContract, tokenId }: BgNftAdapterRequest) => {
  const bgAdapter = await bgAdapterContract({ bgNftContract });
  // return await bgAdapter.methods.getDataUriSvg(tokenId).call();
  return readFromCacheOrCallMethod(bgAdapter, "getDataUriSvg", [tokenId]);
}

export const bgNftAdapterGetDataUriBase64 = async ({ bgNftContract, tokenId }: BgNftAdapterRequest) => {
  const bgAdapter = await bgAdapterContract({ bgNftContract });
  // return await bgAdapter.methods.getDataUriBase64(tokenId).call();
  return readFromCacheOrCallMethod(bgAdapter, "getDataUriBase64", [tokenId]);
}

export const bgNftAdapterGetEmbeddableSvg = async ({ bgNftContract, tokenId }: BgNftAdapterRequest) => {
  const bgAdapter = await bgAdapterContract({ bgNftContract });
  // return await bgAdapter.methods.getEmbeddableSvg(tokenId).call();
  return readFromCacheOrCallMethod(bgAdapter, "getEmbeddableSvg", [tokenId]);
}

export const pfpNftAdapterGetSvg = async ({ pfpContractAddress, tokenId }: PfpNftAdapterRequest) => {
  const pfpAdapter = await pfpAdapterContract({ pfpContractAddress });
  // return await pfpAdapter.methods.getSvg(tokenId).call();
  return readFromCacheOrCallMethod(pfpAdapter, "getSvg", [tokenId]);
}

export const pfpNftAdapterGetDataUriSvg = async ({ pfpContractAddress, tokenId }: PfpNftAdapterRequest) => {
  const pfpAdapter = await pfpAdapterContract({ pfpContractAddress });
  // return await pfpAdapter.methods.getDataUriSvg(tokenId).call();
  return readFromCacheOrCallMethod(pfpAdapter, "getDataUriSvg", [tokenId]);
}

export const pfpNftAdapterGetDataUriBase64 = async ({ pfpContractAddress, tokenId }: PfpNftAdapterRequest) => {
  const pfpAdapter = await pfpAdapterContract({ pfpContractAddress });
  // return await pfpAdapter.methods.getDataUriBase64(tokenId).call();
  return readFromCacheOrCallMethod(pfpAdapter, "getDataUriBase64", [tokenId]);
}

export const pfpNftAdapterGetEmbeddableSvg = async ({ pfpContractAddress, tokenId }: PfpNftAdapterRequest) => {
  const pfpAdapter = await pfpAdapterContract({ pfpContractAddress });
  // return await pfpAdapter.methods.getEmbeddableSvg(tokenId).call();
  return readFromCacheOrCallMethod(pfpAdapter, "getEmbeddableSvg", [tokenId]);
}

export const pfpNftAdapterIsNotYours = async ({ pfpContractAddress, tokenId }: PfpNftAdapterRequest) => {
  const pfpAdapter = await pfpAdapterContract({ pfpContractAddress });
  const user = await getConnectedAddress();
  return pfpAdapter.methods.ownerOf(tokenId).call()
    .then((owner: string) => user !== owner);
}

export const bgNftAdapterIsNotYours = async ({ bgNftContract, tokenId }: BgNftAdapterRequest) => {
  const bgAdapter = await bgAdapterContract({ bgNftContract });
  const user = await getConnectedAddress();
  return bgAdapter.methods.ownerOf(tokenId).call()
    .then((owner: string) => user !== owner);
}
