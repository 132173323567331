import { abi as ERC721 } from "../contract-data/ERC721.json";
import { abi as PfpBg } from "../contract-data/PfpBg.json";
import { abi as CollectionPfpBgManager } from "../contract-data/CollectionPfpBgManager.json";
import { abi as CollectionPfpBg } from "../contract-data/CollectionPfpBg.json";
import { abi as NftAdapter } from "../contract-data/PunksAdapter.json";
import { getContract, getContractByAddress, Network } from "@cyberpnk/component-library";
import { AbiItem } from "web3-utils";
import addresses from "../contract-data/contract-addresses.json";
import punkIcon from "../images/punk2121.png";
import anonymiceIcon from "../images/mouse.png";
import anonymiceBabyIcon from "../images/baby_mouse.svg";
import luchadorIcon from "../images/ElCaballeroEsmeralda.png";
import ethterrestrialIcon from "../images/ethterrestrial.svg";
import terraformIcon from "../images/terraform_1235.svg";
import autoglyphIcon from "../images/autoglyph.svg";
import headscapeIcon from "../images/headscapes_16.svg";
import kinochromeIcon from "../images/kinochrome_53.svg";
import ponzirugIcon from "../images/ponzirug_0.svg";

export const getPfpBgContract = async () => getContract("PfpBg", addresses, PfpBg as AbiItem[]);

export const getCollectionPfpBgManagerContract = async () => getContract("CollectionPfpBgManager", addresses, CollectionPfpBgManager as AbiItem[]);

export const getCollectionPfpBgContract = async (address: string) => getContractByAddress(address, CollectionPfpBg as AbiItem[]);

export const getErc721Contract = async (address: string) => getContractByAddress(address, ERC721 as AbiItem[]);

export const getNftAdapterContract = async (address: string) => getContractByAddress(address, NftAdapter as AbiItem[]);

export interface PfpNft {
  name: string,
  icon: string,
  pfpContractAddress: string
}

export const getPfps = (network: Network): PfpNft[] => {
  if (!addresses?.[network]) {
    return [];
  }
  return [
    {
      name: "CryptoPunks",
      icon: punkIcon,
      pfpContractAddress: addresses[network].CryptoPunksMarket
    },
    {
      name: "Anonymice",
      icon: anonymiceIcon,
      pfpContractAddress: addresses[network].Anonymice
    },
    {
      name: "Baby Anonymice",
      icon: anonymiceBabyIcon,
      pfpContractAddress: addresses[network].AnonymiceBreeding
    },
    {
      name: "EthTerrestrials",
      icon: ethterrestrialIcon,
      pfpContractAddress: addresses[network].EthTerrestrials
    },
    {
      name: "Luchadores",
      icon: luchadorIcon,
      pfpContractAddress: addresses[network].Luchadores
    },
  ];
};

export interface BgNft {
  name: string,
  icon: string,
  bgContractAddress: string
}


export const getBgs = (network: Network): BgNft[] => {
  if (!addresses?.[network]) {
    return [];
  }
  return [
    {
      name: "Terraforms",
      icon: terraformIcon,
      bgContractAddress: addresses[network].Terraforms
    },
    {
      name: "Autoglyphs",
      icon: autoglyphIcon,
      bgContractAddress: addresses[network].Autoglyphs
    },
    {
      name: "Headscapes",
      icon: headscapeIcon,
      bgContractAddress: addresses[network].Headscapes
    },
    {
      name: "Kinochromes",
      icon: kinochromeIcon, 
      bgContractAddress: addresses[network].Kinochromes
    },
    {
      name: "PonziRugs",
      icon: ponzirugIcon,
      bgContractAddress: addresses[network].PonziRugs
    },
  ];
};

